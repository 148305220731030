import React from 'react';
import SEO from '../components/seo';
import IndustryCard from '../components/sections/industries/industry-card';

interface Industry {
  name: string;
  description: string;
  link: string;
}
const IndustriesPage = () => {
  const industries = [
    {
      name: 'Bar & Nightclub',
      description:
        'We are the largest ID scanning network in North America. Our customers use Patronscan to keep known troublemakers out by leveraging the power of the network. Patronscan also helps them to identify fake IDs  by using the most advanced ID authentication. Sophisticated bars use Patronscan’s VIP and Guestlist features to enhance their customer’s experience and increase their loyalty.',
      link: '/id-scanner-for-bars',
    },
    {
      name: 'Property Management',
      description:
        'Renting to known fraudsters can cost you millions of dollars every year. Our customers use Patronscan to identify known fraudsters before they rent a property. With our industry leading fake ID detectors and do not rent lists, you can be assured that your customer is who they say they are. ',
      link: '/industry/property-management',
    },
    {
      name: 'Telecom',
      description:
        'Telecom fraud is increasing every year, known fraudsters are able to game the system by using fake IDs and signing up for long contracts to get free phones. Patronscan is used to validate the identity of your customer before they can sign up for long term contracts. Let Patronscan assure you that your new customers are real people, and not fraudsters.',
      link: '/industry/telecom',
    },
    {
      name: 'Automotive',
      description:
        'With the prevalence of fake IDs, it’s easy for known criminals to steal cars under fake identities. Our customers use Patronscan to validate identities before cars can be rented at their locations. With our easy to use interface, industry leading fake ID detector and do not rent list, Patronscan provides assurance with minimal business impact.',
      link: '/industry/vehicle-rental',
    },
  ];
  let industryCount = 0;
  return (
    <div className="bg-white">
      <SEO title="Who We Help - Patronscan" />
      <section id="industry-list" className="max-w-container mx-auto py-24 lg:py-48">
        <div className="pl-4 md:pl-8 mb-8">
          <h2 className="text-blue text-5xl font-heading font-hairline mb-4">Industries We Help</h2>
          <p className="text-grey-darker max-w-2xl">
            <span className="text-blue">Patronscan</span> has multiple off the shelf and cutomized
            solutions for a multitude of businesses. Please select your industry to see how
            Patronscan can help you.
          </p>
        </div>
        <div className="flex flex-col lg:max-h-container flex-wrap">
          {industries.map((industry: Industry) => {
            industryCount++;
            return (
              <IndustryCard
                key={industryCount}
                industry={industry}
                blue={industryCount % 2 === 0}
                newColumn={industryCount === 3}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};
export default IndustriesPage;
