import React, { FC } from 'react';
import AniLink from "gatsby-plugin-transition-link/AniLink"

interface IndustryCardProps {
    blue?: boolean;
    newColumn: boolean;
    industry: {
        name: string;
        description: string;
        link: string;
    }
}
const IndustryCard: FC<IndustryCardProps> = (props) => {

    const classes = props.blue ? 'bg-blue text-white' : 'border border-grey-dark text-grey-darker'
    return (
        <div className="p-4 lg:w-1/2">
            <div className={`p-8 lg:p-12 ${classes} ${props.newColumn ? 'lg:mb-2 lg:mt-32' : 'my-2'}`}>
                <AniLink fade to={props.industry.link}>
                    <h2 className="text-3xl font-hairline font-heading">{props.industry.name}</h2>
                    <p className={`my-8 ${props.blue ? 'text-white' : 'text-grey-paragraph'}`}>{props.industry.description}</p>
                    <p className={props.blue ? 'text-white' : 'text-blue'}>See Solution</p>
                </AniLink>
            </div>
        </div>
    )
}

export default IndustryCard;